<template>
  <tooltip :text="tooltip" class="inline-block">
    <fa-icon :icon="icon" class="text-luxcaddy-500 hover:text-luxcaddy-600 cursor-pointer"
             @click="onClick()"></fa-icon>
  </tooltip>
</template>

<script>
import Tooltip from "../tooltips/Tooltip";

export default {
  name: "IconButtonElement",
  components: {Tooltip},
  props: {
    icon: {
      type: String,
      required: true
    },
    tooltip: {
      type: String,
      default: ''
    }
  },
  methods: {
    onClick(e) {
      this.$emit('click', e);
    }
  }
}
</script>

